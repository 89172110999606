import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';
import HamburgerButton from './HamburgerButton';
import Menu from './Menu';
import Footer from './Footer';
import ModalForm from '../components/ModalForm';
import Headroom from 'react-headroom';
import 'tachyons';
import '../css/main.css';

class Layout extends React.Component {
	// ... constructor and toggleMenu from above
	constructor(props) {
		super(props);
		this.state = { showMenu: false };
	}
	toggleMenu = () => {
		this.setState({ showMenu: !this.state.showMenu });
	};
	render() {
		const { children, headerColor } = this.props;
		const { showMenu } = this.state;
		return (
			<StaticQuery
				query={graphql`
					query SiteTitleQuery {
						site {
							siteMetadata {
								title
							}
						}
					}
				`}
				render={(data) => (
					<div className={showMenu ? 'no-scroll' : 'not-active'} menuState={showMenu}>
						<Headroom disableInlineStyles>
							<header
								className={`pl3 pr3 pl5-l pr5-l flex pt3 pb3 justify-between items-center z-9999 w-100 header-${headerColor}`}
							>
								<Link to="/">
									<div className="logo" />
								</Link>
								<div className="flex justify-between items-center">
									<div className="phone-icon" />
									<div className="blue ttu ml3 mr4 phone-number">
										<div className="f6 bold db">Call Us On</div>
										<div className="f4 bold">0800 555 001</div>
									</div>
									<HamburgerButton toggleMenu={this.toggleMenu} />
								</div>
							</header>
						</Headroom>
						<Menu classNames={showMenu ? 'menu-active' : ''} menuState={showMenu} />
						<div className="absolute top-0 w-100">
							<main>{children}</main>
							<Footer />
							<ModalForm />
						</div>
					</div>
				)}
			/>
		);
	}
}

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
