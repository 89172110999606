import React from 'react';
import noScroll from 'no-scroll';
import '../css/modal-form.css';
import { Motion, spring, presets } from 'react-motion';
import MailIcon from './MailIcon';
import CloseIcon from './CloseIcon';
import ContactForm from './ContactForm';

class ModalForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false
		};
	}
	openModal() {
		this.setState({ visible: true });
		noScroll.on();
	}
	closeModal() {
		this.setState({ visible: false });
		setTimeout(function() {
			//Start the timer
			noScroll.off();
		}, 800);
	}
	render() {
		const { visible } = this.state;
		const { pageTitle } = this.props;
		return (
			<div>
				<button
					onClick={this.openModal.bind(this)}
					className={`pointer fixed right-1 right-2-l bottom-1 bottom-2-ns z-100 form-modal-enticer ${visible &&
						'modal-active'}`}
				>
					<MailIcon />
				</button>
				<Motion
					defaultStyle={{
						opacity: 0
					}}
					style={{
						opacity: spring(visible ? 1 : 0, presets.gentle)
					}}
				>
					{(style) => (
						<div
							style={{
								opacity: style.opacity
							}}
							className={`vw-100 vh-100 modal-form z-max ${visible && 'modal-active'}`}
						>
							<div
								className="w-100 vh-100 absolute top-0 left-0 db z-0"
								onClick={this.closeModal.bind(this)}
							/>
							<div className="modal-inner w-100 mw7 center bg-blue relative z-max">
								<button
									onClick={this.closeModal.bind(this)}
									className=" absolute right-1 top-1 pointer"
								>
									<CloseIcon />
								</button>
								<ContactForm pageTitle={pageTitle} />
							</div>
						</div>
					)}
				</Motion>
			</div>
		);
	}
}
export default ModalForm;
