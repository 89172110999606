import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import FaceBook from './FaceBook';
import LinkedIn from './LinkedIn';
import Sponsors from './Sponsors';
import BraveStrap from './BraveStrap';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        prismicLegals {
          data {
            standard_terms {
              url
            }
          }
        }
      }
    `}
    render={(data) => (
      <footer className="mt4 bg-blue pl3 pr3 pt5 pb5 pa5-l">
        <div className="flex-l w-100">
          <div className="w-100 w-3-l f3 blue medium mb5 mb3-l">DBR Limited</div>
          <div className="w-100 w-3-l contact-details">
            <div className="f5 dbr-border mb4">
              <p>30 The Esplanade</p>
              <p>Eastern Beach</p>
              <p>Auckland 2012</p>
            </div>
          </div>
          <div className="w-100 w-3-l contact-details">
            <div className="f5 dbr-border mb4">
              <p>
                <strong className="w3 dib">FREE</strong> 0800 555 001 (NZ ONLY)
              </p>
              <p>
                <strong className="w3 dib">EMAIL</strong>{' '}
                <a href="mailto:laura@dbr.nz" className="link black">
                  laura@dbr.nz
                </a>
              </p>
            </div>
            {/* <div className="f5 dbr-border mb4">
              <strong>FOLLOW US</strong>
              <ul className="list pl0 flex">
                <li className="mr2">
                  <a
                    href="https://www.facebook.com/DBR-Limited-605188906253172/?ref=br_tf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaceBook />
                  </a>
                </li>
                <li>
                  <a href="https://nz.linkedin.com/" target="_blank" rel="noopener noreferrer">
                    <LinkedIn />
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
        <div className="footer-bottom w-100 flex-l bt pt3 mt4 justify-between tc tl-l">
          <div className="w-100 w-50-l lh-copy">
            <ul className="list ttu pl0 f6">
              <li className="dib mr3">
                <Link to="/fine-print" className="link black">
                  Fine Print
                </Link>
              </li>
              <li className="dib">
                <a
                  href={data.prismicLegals.data.standard_terms.url}
                  target="_blank"
                  className="link black"
                  rel="noopener noreferrer"
                >
                  General Terms Conditions
                </a>
              </li>
            </ul>
            <p className="f6">Copyright &copy; DBR Ltd. All Rights Reserved. </p>
          </div>
          <div className="tc">
            <Sponsors />
            <a
              href="https://bravedigital.nz"
              target="_blank"
              className="georgia link black mt3 db"
              rel="noopener noreferrer"
            >
              <BraveStrap />
            </a>
          </div>
        </div>
      </footer>
    )}
  />
);
export default Footer;
