import React from 'react';
import { navigateTo } from 'gatsby-link';
import '../css/forms.css';
import noScroll from 'no-scroll';

function encode(data) {
	return Object.keys(data)
		.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&');
}

export default class ContactForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const form = e.target;
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				'form-name': form.getAttribute('name'),
				...this.state
			})
		})
			.then(() => navigateTo(form.getAttribute('action')))
			.catch((error) => alert(error));
		noScroll.off();
	};

	render() {
		const { pageTitle } = this.props;
		return (
			<div className="w-100 pl4 pr4 bg-tint-yellow pt5 pb4 pt5-l pb5-l">
				<div className="f2 g-medium tc center mb5">{pageTitle} Enquiry</div>
				<form
					name="DBR Conact Form"
					method="post"
					action="/thanks/"
					data-netlify="true"
					data-netlify-honeypot="bot-field"
					onSubmit={this.handleSubmit}
					className="mw6 center"
				>
					{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
					<input type="hidden" name="form-name" value="contact" />
					<p hidden>
						<label>
							Don’t fill this out: <input name="bot-field" onChange={this.handleChange} />
						</label>
					</p>
					<p>
						<input
							type="text"
							name="name"
							onChange={this.handleChange}
							className="w-100 input-reset pa3"
							placeholder="Full Name"
							required="true"
						/>
					</p>
					<p>
						<input
							type="email"
							name="email"
							onChange={this.handleChange}
							className="w-100 input-reset pa3"
							placeholder="Email Address"
							required="true"
						/>
					</p>
					<p>
						<textarea
							name="message"
							onChange={this.handleChange}
							className="w-100 input-reset pa3 h4"
							placeholder="Your Message"
						/>
					</p>
					<p>
						<button
							type="submit"
							className="link black button g-medium ttu f5 tracked flex items-center center mt4"
						>
							Submit
						</button>
					</p>
				</form>
			</div>
		);
	}
}
