import React from 'react';
import {Link} from 'gatsby';
import {Motion, spring, presets} from 'react-motion';

class Menu extends React.Component {
  render() {
    return (
      <Motion
        defaultStyle={{
          opacity: 0
        }}
        style={{
          opacity: spring(this.props.menuState ? 1 : 0, presets.gentle)
        }}
      >
        {style => (
          <div
            style={{
              opacity: style.opacity
            }}
            className={`fixed top-0 left-0 bg-blue h-100 w-100 blue tc menu z-999 flex items-center  ${this.props.classNames}`}
          >
            <div className="measure-widest ml-auto mr-auto tl flex-l w-100 justify-between pl3 pr3 pb4">
              <ul className="list main nav medium f2 f1-l mt0 mb0 w-100 w-50-l pl0">
                <li>
                  <Link to="/about-us" className="link blue db pt2 pb2 pt3-l pb3-l">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/who-is-dbr" className="link blue db pt2 pb2 pt3-l pb3-l">
                    Who is DBR
                  </Link>
                </li>
                <li>
                  <Link to="/our-solutions" className="link blue db pt2 pb2 pt3-l pb3-l">
                    Our Solutions
                  </Link>
                </li>
                <li>
                  <Link to="/our-team" className="link blue db pt2 pb2 pt3-l pb3-l">
                    Our Team
                  </Link>
                </li>
              </ul>
              <ul className="f4 f3-l medium nav list mt0 mb0 w-100 w-50-l pl0">
                <li>
                  <Link to="/how-we-work" className="link blue db pt2 pb2 pt3-l pb3-l">
                    How we work
                  </Link>
                </li>
                <li>
                  <Link to="/non-bank-lender" className="link blue db pt2 pb2 pt3-l pb3-l">
                    Working with a non-bank lender
                  </Link>
                </li>
                <li>
                  <Link to="/faqs" className="link blue db pt2 pb2 pt3-l pb3-l">
                    FAQs
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="link blue db pt2 pb2 pt3-l pb3-l">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div className="buttons w-100 absolute bottom-1 pl3 pr3 justify-between">
              <a href="tel:0800555001" className="button ttu blue-button db w-100 mb1">
                Call Us Now
              </a>
            </div>
          </div>
        )}
      </Motion>
    );
  }
}

export default Menu;
